import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PostSingle from "../../Blog/PostSingle/PostSingle"

const getLatestPost = graphql`
    query {
        latestPost: allContentfulPost(sort: {fields: createdAt order: DESC}) {
            edges {
                node {
                    slug
                    title
                    category {
                        title
                    }
                    id: contentful_id
                    createdAt(formatString: "Do MMMM, Y")
                    mainImage {
                        fluid {
                            ...GatsbyContentfulFluid_tracedSVG
                        }
                    }
                    content {
                        json
                    }
                }
            }
        }
    }
`

const LatestPost = () => {
  const response = useStaticQuery(getLatestPost)
  const lastPost = response.latestPost.edges[0]
  return (
    <section className='container'>
      <hr/>
      <h2 className='text-center'>Latest Post</h2>
      <PostSingle post={lastPost.node}/>

    </section>
  )
}

export default LatestPost
