import React from "react"
import Layout from "../components/Layout/Layout"
import LatestPost from "../components/Home/LatestPost/LatestPost"
import About from "../components/Home/About/About"
import SEO from "../components/SEO"

export default () => (
  <Layout>
    <SEO title='Home' description='The Jolly Mommy Blog'/>
    <section className='container'>
      <About/>
      <LatestPost/>
    </section>
  </Layout>
)
