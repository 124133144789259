import React from "react"
import img from "../../../images/about.jpg"
import styles from "./about.module.css"

const About = () => {
  return (
    <section className='container mt-5'>
      <div className="row">
        <div className="col-md-3 m-auto" onContextMenu={(e)=> e.preventDefault()}>
          <img src={img} alt="portrait" className='img-fluid rounded-pill'/>
        </div>
        <div className="col-md-9 m-auto">
          <p className={styles.aboutContent}>Hi <span role='img' aria-label='waves'>&#128075;</span> I'm Lorretta 39 years young.</p>
          <p className={styles.aboutContent}>James is my partner who I have been with for a little under 7
            years. Yes I'm still waiting for him to pop the question. His reply is "if it's not broke why fix it ". We
            live in Worcestershire UK.</p>
          <p>I'm a busy Mum to 4 little darlings. Guy 16, Izak 13, Tila 10 and Hudson 4. When
            I'm not being mum I work as a preschool assistant. It involves wiping noses, bottoms and making LOTS of play
            doh. I am also training to be a hairdresser and working through my level 3.</p>
          <p>I created this blog as a way to
            vent, chat and all the stuff in between and to see if anyone would actually read it.</p>
        </div>
      </div>

    </section>
  )
}

export default About
